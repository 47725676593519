<template>
    <div v-if="isEnabled">
        <slot></slot>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'p-feature-flag',
    props: {
        name: String
    },
    computed: {
        ...mapGetters(['featureFlagEnabled']),
        isEnabled() {
            return this.featureFlagEnabled(this.name);
        }
    }
};
</script>

<style>
</style>
