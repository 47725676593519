<template>
  <ValidationProvider
    tag="div"
    :rules="rules"
    :name="label || name"
    :vid="vid"
    :mode="mode"
    v-slot="{ classes, errors }"
  >
    <b-form-group :label-for="name || labelText" :label="labelText" :class="{ 'mb-0' : compactFormat}" >
      <b-input-group>
      <b-form-select
        v-bind="$attrs"
        :name="name"
        :value="value"
        :options="filteredOptions"
        @change="handleChange($event)"
        @input="handleSelection($event)"
        :class="{ ...classes,  'compact-control' : compactFormat,  'py-0' : compactFormat}"
      >
        <template v-slot:first v-if="noSelectionMessage">
          <b-form-select-option :value="null">{{noSelectionMessage}}</b-form-select-option>
        </template>
     </b-form-select>
        <template v-slot:append v-if="infoMessage">
            <b-input-group-text class="px-1"><b-icon-info-circle v-b-tooltip.hover :title="infoMessage"></b-icon-info-circle ></b-input-group-text>
        </template>
        <template
            v-for="(_, name) in $scopedSlots"
            :slot="name"
            slot-scope="slotData"
        >
            <slot :name="name" v-bind="slotData" />
        </template>
        </b-input-group>
        <slot name="error-message" v-if="errors && errors.length > 0">
          <!-- allow for easy way to set a custom error message -->
          <span>{{ errors[0] }}</span>
        </slot>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import selectListOptionsDataContext from "@/services/selectListOptions.dataContext.js";
import baseInputs from "@/components/mixins/BaseInputs";
import validatedInput from "@/components/mixins/ValidatedInput";
import store from '@/store/store.js';

export default {
  name: "p-select",
  mixins: [baseInputs, validatedInput],
  props: {
    value: [String, Number, Object, Boolean],
    noSelectionMessage: String,
    dataType: String,
    options: Array,
    filter: [String,Number,Function],
    addEmptyOption:Boolean,
    readOnly: Boolean,
    compactFormat: Boolean,
    setInvalidValueToFirstOption: {
        type: Boolean,
        default: false
    },
    emptyOnNullFilter: {
        type: Boolean,
        default: false
    },
    addInvalidOption: {
        type: Object,
        default: null
    }
  },
  data() {
    return {
      innerOptions: []
    };
  },
  async created() {
    if (this.dataType) {
      // can't do this in a computed property because b-form-select :options requires an Array, not a Promise.
      this.innerOptions = await this.loadList(this.dataType);
      this.unsubscribe = store.subscribe((mutation) => {
          if (this.dataType && mutation.type.toLowerCase().includes(this.dataType.toLowerCase()) && mutation.payload.length > 0) {
              this.innerOptions = mutation.payload;
              this.setInnerOptionsToObjects();
          }
      });
    } else {
      this.innerOptions = this.options;
    }
    this.setInnerOptionsToObjects();
  },
  beforeDestroy() {
      if(this.unsubscribe)
      {
        this.unsubscribe();
      }
  },
  computed: {
    filteredOptions(){
        let filteredOptions = this.innerOptions;
        if(this.filter || this.emptyOnNullFilter){

          if((typeof this.filter == 'function')){
            filteredOptions = filteredOptions.filter(x => this.filter(x));
          }
          else{
            filteredOptions = filteredOptions.filter(x => x.filter == this.filter);
          }
        }
        if(this.addInvalidOption){
          filteredOptions = [this.addInvalidOption, ...filteredOptions]
        }
        if(this.addEmptyOption){
          return [{value:null, text: ''}, ... filteredOptions]
        }
        return filteredOptions;
    },
  },
  watch: {
      options: {
      handler: function() {
          this.innerOptions = this.options;
          this.setInnerOptionsToObjects();
      }
    },
    filteredOptions: {
      handler: function() {
        //make sure inneroptions isn't empty, so it doesn't clear the value before options are loaded.
        if(this.filteredOptions && this.innerOptions.length > 0 && !this.filteredOptions.some( x=> x.value == this.value)){
          //if value isn't a valid option, clear it. (or set to first if configured)
          if(this.setInvalidValueToFirstOption && this.filteredOptions.length > 0){
            this.handleSelection(this.filteredOptions[0].value);
          }
          else{
            this.handleInput(null);
          }
        }
      }
    }
  },
  methods: {
    setInnerOptionsToObjects(){
      //to match bootstrap selects
      //If option array is a list of strings, it will be used for both the generated value and text fields.
      if(this.innerOptions.length > 0 && typeof(this.innerOptions[0]) === "string"){
          this.innerOptions = this.innerOptions.map((x) => ({text: x, value:x}));
      }
    },
    async loadList(dataType) { 
      return await selectListOptionsDataContext.getStoreDropdownData(dataType);
    },
    handleSelection(selectionValue){
      this.handleInput(selectionValue);

      var option = this.innerOptions.find(x => x.value == selectionValue) || {};

      this.$emit("filter", option.filter);
    },
    handleChange(event){
        var option = this.innerOptions.find(x => x.value == event) || {};
        this.$emit("selectedObject", option);
        this.$emit('change', event);
    }
  }
};
</script>


<style scoped lang="scss">
/deep/ .compact-control {
    padding-bottom: 5px;
    padding-top: 8px;
    padding-left: 2px;
    height: 1.5rem;
}
</style>

